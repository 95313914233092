/* Overriding element hover */
.optionContainer li:hover,
.optionContainer .highlight {
  background:#049092;
  color: #fff;
}
.multiSelectContainer input{
  font-size: 16px;
  margin-top: 10px
}
/* Ends overriding */